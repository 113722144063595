import { useEffect, useState, useRef } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function EditOLA({ data = {}, removeOLA, programmeID, saveData }) {
  const [name, setName] = useState(data.name || "");
  const [contactQ1, setContactQ1] = useState(data.contactQ1 || 0);
  const [contactQ2, setContactQ2] = useState(data.contactQ2 || 0);
  const [contactQ3, setContactQ3] = useState(data.contactQ3 || 0);
  const [contactQ4, setContactQ4] = useState(data.contactQ4 || 0);
  const [semester, setSemester] = useState(data.semester || 0);
  const prevSemester = usePrevious(semester);
  const [ectsCredits, setECTSCredits] = useState(data.ectsCredits || 0);
  const [realECTSCredits, setRealECTSCredits] = useState(data.realECTSCredits || 0);
  const [phases, setPhases] = useState(data.phases || []); // OLAPhase
  const [allPhases, setAllPhases] = useState([]);
  const [internship, setInternship] = useState(data.internship || false);
  const [planPerPhase, setPlanPerPhase] = useState(data.planPerPhase || false);
  const [zCode, setZCode] = useState(data.zCode || "");
  const [internshipPercentage, setInternshipPercentage] = useState(data.internshipPercentage || 0);

  useEffect(() => {
    const fetchData = async () => {
      const classResponse = await api.getPhases(programmeID);
      classResponse.sort((a, b) => b.name < a.name);
      setAllPhases(classResponse);
    };
    fetchData();
  }, [programmeID]);

  useEffect(() => {
    if (!prevSemester || prevSemester === semester) {
      return;
    }

    // sem 1 to sem 2
    if (prevSemester === 1 && semester === 2) {
      setContactQ3(contactQ1);
      setContactQ4(contactQ2);
      setContactQ1(0);
      setContactQ2(0);
    }

    // sem 2 to sem 1
    if (prevSemester === 2 && semester === 1) {
      setContactQ1(contactQ3);
      setContactQ2(contactQ4);
      setContactQ3(0);
      setContactQ4(0);
    }

    // year to sem 1
    if (prevSemester === 0 && semester === 1) {
      setContactQ3(0);
      setContactQ4(0);
    }

    // year to sem 2
    if (prevSemester === 0 && semester === 2) {
      setContactQ1(0);
      setContactQ1(0);
    }
  }, [semester]);

  useEffect(() => {
    data.name = name;
    data.contactQ1 = contactQ1;
    data.contactQ2 = contactQ2;
    data.contactQ3 = contactQ3;
    data.contactQ4 = contactQ4;
    data.semester = semester;
    data.ectsCredits = ectsCredits;
    data.phases = phases;
    data.internship = internship;
    data.planPerPhase = planPerPhase;
    data.zCode = zCode;
    data.realECTSCredits = realECTSCredits;
    data.internshipPercentage = internshipPercentage;
    saveData(data);
  }, [name, contactQ1, contactQ2, contactQ3, contactQ4, semester, ectsCredits, phases, internship, planPerPhase, zCode, realECTSCredits, internshipPercentage]);

  const togglePhase = (phaseID, checked) => {
    if (checked) {
      setPhases([
        ...phases,
        {
          phaseID,
          surplus: 0,
          numberOfClassesOverride: 0,
          olaID: data.id || 0,
        },
      ]);
    } else {
      setPhases(phases.filter(l => l.phaseID !== phaseID));
    }
  };

  const setSurplus = (phaseID, surplus) => {
    const newPhases = phases.map(l => {
      if (l.phaseID === phaseID) {
        l.surplus = surplus;
      }
      return l;
    });
    setPhases(newPhases);
  };

  const setNumberOfClassesOverride = (phase, numberOfClassesOverride) => {
    if (phase.numberOfClasses === numberOfClassesOverride) {
      numberOfClassesOverride = 0;
    }
    const newPhases = phases.map(l => {
      if (l.phaseID === phase.ID) {
        l.numberOfClassesOverride = numberOfClassesOverride;
      }
      return l;
    });
    setPhases(newPhases);
  };

  return (
    <Row className="border m-3 p-2">
      <Col xs={11}>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Naam</Form.Label>
            <Form.Control type="text" required onChange={e => setName(e.target.value)} value={name} />
          </Form.Group>
          <Row>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Semester</Form.Label>
                <Form.Select onChange={e => setSemester(parseInt(e.target.value, 10))} value={semester}>
                  <option value={0}>Jaarvak</option>
                  <option value={1}>Semester 1</option>
                  <option value={2}>Semester 2</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Studiepunten SAT</Form.Label>
                <Form.Control type="number" min="0" required onChange={e => setECTSCredits(parseInt(e.target.value, 10))} value={ectsCredits} />
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Studiepunten ECTS</Form.Label>
                <Form.Control type="number" min="0" required onChange={e => setRealECTSCredits(parseInt(e.target.value, 10))} value={realECTSCredits} />
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Label>Z-code OLA</Form.Label>
                <Form.Control type="text" required onChange={e => setZCode(e.target.value)} value={zCode} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Stage" checked={internship} onChange={e => setInternship(e.target.checked)} />
              </Form.Group>
            </Col>
            {internship && (
              <>
                <Col xs={6}>
                  <Form.Label>Percentage per stage</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.01"
                    required
                    onChange={e => setInternshipPercentage(parseFloat(e.target.value, 10))}
                    value={internshipPercentage}
                  />
                </Col>
              </>
            )}
          </Row>
          {internship || (
            <>
              <Form.Label>Contacturen</Form.Label>
              <Row>
                <Col xs={3}>
                  <Form.Label>Q1</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ1(parseFloat(e.target.value, 10))}
                    value={contactQ1}
                    disabled={data.semester === 2}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Q2</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ2(parseFloat(e.target.value, 10))}
                    value={contactQ2}
                    disabled={data.semester === 2}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Q3</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ3(parseFloat(e.target.value, 10))}
                    value={contactQ3}
                    disabled={data.semester === 1}
                  />
                </Col>
                <Col xs={3}>
                  <Form.Label>Q4</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    step="0.5"
                    required
                    onChange={e => setContactQ4(parseFloat(e.target.value, 10))}
                    value={contactQ4}
                    disabled={data.semester === 1}
                  />
                </Col>
              </Row>
            </>
          )}
          <br />
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Plan per fase" checked={planPerPhase} onChange={e => setPlanPerPhase(e.target.checked)} />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Fasen</Form.Label>
            {allPhases.length === 0 && <p>Selecteer eerst een opleiding</p>}
            {allPhases.map(c => (
              <Row style={{ height: "54px" }} key={c.ID}>
                <Col xs={3}>
                  <Form.Check
                    type="checkbox"
                    label={c.name}
                    checked={phases.find(l => l.phaseID === c.ID)}
                    onChange={e => togglePhase(c.ID, e.target.checked)}
                  />
                </Col>
                {phases.find(l => l.phaseID === c.ID) && (
                  <Col xs={9}>
                    <Form.Group as={Row} className="mb-3 surplus-studenten" controlId="formPlaintextEmail">
                      {c.numberOfStudents} +{" "}
                      <Form.Control
                        inline
                        type="number"
                        required
                        onChange={e => setSurplus(c.ID, parseInt(e.target.value, 10))}
                        value={phases.find(l => l.phaseID === c.ID).surplus}
                      />{" "}
                      studenten /{" "}
                      <Form.Control
                        type="number"
                        required
                        onChange={e => setNumberOfClassesOverride(c, parseInt(e.target.value, 10))}
                        value={
                          phases.find(l => l.phaseID === c.ID).numberOfClassesOverride > 0
                            ? phases.find(l => l.phaseID === c.ID).numberOfClassesOverride
                            : c.numberOfClasses
                        }
                      />{" "}
                      klassen
                    </Form.Group>
                  </Col>
                )}
              </Row>
            ))}
          </Form.Group>
        </Form>
      </Col>
      <Col xs={1}>
        <Button variant="danger" onClick={() => removeOLA()}>
          <i className="far fa-trash" />
        </Button>
      </Col>
    </Row>
  );
}

export default EditOLA;
