import { useEffect, useState } from "react";
import { Row, Container, Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import EditModal from "./EditModal";
import RemoveModal from "./RemoveModal";

import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import { downloadBlob } from "../../_helpers/excel";

const api = SatAPI();

function Campusses() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [campusses, setCampusses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);
  const [gridRef, setGridRef] = useState(null);

  const loadCampusses = async () => {
    try {
      setLoading(true);
      const response = await api.getCampusses(snapshot);
      response.sort((a, b) => b.name < a.name ? 1 : -1);
      setCampusses(response);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: `${error}` });
    }
  };

  const openEditModal = campus => {
    setEditModal(
      <EditModal
        hideModal={() => {
          setEditModal(null);
          loadCampusses();
        }}
        data={campus}
      />,
    );
  };

  const openRemoveModal = campus => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          loadCampusses();
        }}
        data={campus}
      />,
    );
  };

  const gridStyle = { minHeight: 550 }

  const initialColumns = [
    { name: 'name', header: 'Naam', defaultFlex: 1 },
    {
      name: 'ID', header: 'Acties', defaultFlex: 1, draggable: false, sortable: false, render: ({ data }) => <div>
        <Button variant="info" onClick={() => openEditModal(data)} disabled={snapshot}>
          <i className="far fa-edit" />
        </Button>
        <Button variant="danger" onClick={() => openRemoveModal(data)} disabled={snapshot}>
          <i className="far fa-trash" />
        </Button></div>
    }
  ];

  const [columns] = useState(initialColumns);

  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' }
  ];

  const exportCSV = () => {
    const { visibleColumns } = gridRef.current;

    const header = visibleColumns.map((c) => c.name).join(',');
    const rows = gridRef.current.data.map((data) => visibleColumns.map((c) => data[c.id]).join(','));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob, 'campussen.csv');
  };

  useEffect(() => {
    loadCampusses();
  }, [snapshot]);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {editModal}
      {removeModal}
      <Row>
        <h1>Campussen</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="secondary" onClick={() => exportCSV()}>
            <i className="far fa-file-export" /> Exporteren
          </Button>{" "}
          <Button variant="success" onClick={() => openEditModal()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactDataGrid
            handle={setGridRef}
            idProperty="ID"
            style={gridStyle}
            defaultFilterValue={filterValue}
            columns={columns}
            dataSource={campusses}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Campusses;
